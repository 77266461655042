<template>
  <div id="divide-table">
    <div style="grid-area: canvas">
      <canvas ref="canvas" />
    </div>
    <h4 style="grid-area: left-title">{{ $t('leftSplit') }}</h4>
    <h4 style="grid-area: right-title">{{ $t('rightSplit') }}</h4>
    <div style="grid-area: left-in">
      <InputNumber
            @focus="$event.target.select()"
            v-model.number="leftSplit" @input="leftChange"
            suffix=" m"
            :minFractionDigits="1" :maxFractionDigits="1"
            :min="0.1" :max="maxLength"/>
    </div>
    <h5 style="grid-area: length">{{ $t('length') }}</h5>
    <div style="grid-area: right-in">
      <InputNumber
            @focus="$event.target.select()" @input="rightChange"
            v-model.number="rightSplit"
            suffix=" m"
            :minFractionDigits="1" :maxFractionDigits="1"
            :min="0.1" :max="maxLength"/>
    </div>
    <div style="grid-area: left-out">{{ `${leftArea.toFixed(2)} m²` }}</div>
    <h5 style="grid-area: area">{{ $t('catchmentArea') }}</h5>
    <div style="grid-area: right-out">{{ `${rightArea.toFixed(2)} m²` }}</div>
  </div>
</template>

<script>
import 'core-js/features/math/clamp';
import {fabric} from "fabric";

export default {
  name: "DivideWindow",
  props: ['selectedArea', 'side', 'resultingArea'],
  emits: ['updateLeft', 'updateRight'],
  data() {
    return {
      canvas: null,
      leftArea: 0,
      rightArea: 0,
    };
  },
  mounted() {
    const middle = this.selectedArea.length_m / 2;
    if (middle * 10 % 1 === 0) {
      this.leftSplit = this.rightSplit = middle;
    } else {
      const difference = middle * 10 % 1 / 10;
      this.leftSplit = middle + difference;
      this.rightSplit = middle - difference;
    }

    this.canvas = new fabric.Canvas(this.$refs.canvas);
    this.drawInitialDrawing();
  },
  computed: {
    leftSplit: {
      get() {
        return this.resultingArea.leftSplit;
      },
      set(value) {
        this.$emit('updateLeft', value);
      }
    },
    rightSplit: {
      get() {
        return this.resultingArea.rightSplit;
      },
      set(value) {
        this.$emit('updateRight', value);
      }
    },
    maxLength() {
      return this.selectedArea.length_m - 0.1;
    },
    scale() {
      const xRatio = this.canvas.width / this.selectedArea.length_m;
      const yRatio = this.canvas.height / Math.max(this.selectedArea.start_width_m, this.selectedArea.end_width_m);
      return Math.min(xRatio, yRatio);
    },
    permeabilityColour() {
      return this.selectedArea.permeability.colour;
    },
    splitColour() {
      const [r, g, b, ] = fabric.Color.sourceFromHex(this.permeabilityColour);
      //Aim for contrast rather than straight inversion: https://stackoverflow.com/a/3943023/112731
      //Especially effective for greys where the inverted colour is often another similar shade of grey
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
      //return `#${fabric.Color.fromSource([255 - r, 255 - g, 255 - b]).toHex()}`;
    },
  },
  methods: {
    drawInitialDrawing() {
      this.canvas.clear();

      let points;
      if (this.side === 'left') {
        const longHeight = Math.max(this.selectedArea.start_width_m, this.selectedArea.end_width_m);

        points = [{
          x: 0,
          y: longHeight * this.scale
        }, {
          x: 0,
          y: (longHeight - this.selectedArea.start_width_m) * this.scale,
        }, {
          x: this.selectedArea.length_m * this.scale,
          y: (longHeight - this.selectedArea.end_width_m) * this.scale
        }, {
          x: this.selectedArea.length_m * this.scale,
          y: longHeight * this.scale
        }];
      } else {
        points = [{
          x: 0,
          y: 0
        }, {
          x: this.selectedArea.length_m * this.scale,
          y: 0
        }, {
          x: this.selectedArea.length_m * this.scale,
          y: this.selectedArea.end_width_m * this.scale
        }, {
          x: 0,
          y: this.selectedArea.start_width_m * this.scale
        }];
      }
      const polygon = new fabric.Polygon(points, {
        id: 'base',
        fill: this.permeabilityColour,
        selectable: false,
        hasBorders: false,
      });
      this.canvas.add(polygon);

      const shapeWidth = this.selectedArea.length_m * this.scale;
      const canvasWidth = this.canvas.getWidth();
      console.assert(canvasWidth >= shapeWidth, "Catchment area fell out of division canvas", polygon);
      this.canvas.relativePan(new fabric.Point((canvasWidth - shapeWidth) / 2, 0));

      this.updateDrawing(this.leftSplit);
    },
    leftChange({value}) {
      this.rightSplit = Math.clamp(this.selectedArea.length_m - value, 0.1, this.maxLength);
      //console.debug(`Left change, balanced to ${value}, ${this.rightSplit}`);
      this.updateDrawing(this.selectedArea.length_m - this.rightSplit);
    },
    rightChange({value}) {
      this.leftSplit = Math.clamp(this.selectedArea.length_m - value, 0.1, this.maxLength);
      console.debug(`Right change, balanced to ${this.leftSplit}, ${value}`);
      this.updateDrawing(this.leftSplit);
    },
    updateDrawing(middleLength) {
      this.canvas.remove(this.canvas.item(1)); //Remove existing line
      console.assert(this.canvas.getObjects().length === 1, `Canvas still has ${this.canvas.getObjects()}`);
      //console.debug(`Removed old drawing, now at ${this.leftSplit}, ${this.rightSplit}`);

      const otherMiddleLength = this.selectedArea.length_m - middleLength;
      const longHeight = Math.max(this.selectedArea.start_width_m, this.selectedArea.end_width_m);
      const shortHeight = Math.min(this.selectedArea.start_width_m, this.selectedArea.end_width_m);
      const heightChange = longHeight - shortHeight;
      const angle = Math.tan(heightChange / this.selectedArea.length_m);
      const middleWidth = shortHeight + (angle * (//The length needs to be the one measured from the angle
          this.selectedArea.end_width_m < this.selectedArea.start_width_m ? otherMiddleLength : middleLength
      ));

      let points, top;
      if (this.side === 'left') {
        points = [middleLength * this.scale, longHeight * this.scale,
                  middleLength * this.scale, (longHeight - middleWidth) * this.scale];
        top = points[3];
      } else {
        top = 0;
        points = [middleLength * this.scale, 0,
                  middleLength * this.scale, middleWidth * this.scale];
      }
      this.canvas.add(new fabric.Line(points, {
        stroke: this.splitColour,
        strokeWidth: 3,
        top: top,
        left: middleLength * this.scale,
        selectable: false,
        hasBorders: false,
      })).renderAll();

      this.leftArea = 0.5 * (this.selectedArea.start_width_m + middleWidth) * middleLength;
      this.rightArea = 0.5 * (this.selectedArea.end_width_m + middleWidth) * otherMiddleLength;
    },
  },
}
</script>

<style scoped lang="scss">
#divide-table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(20px, auto);
  grid-template-areas: "canvas canvas canvas"
                        "left-title . right-title"
                        "left-in length right-in"
                        "left-out area right-out";
  gap: 10px;
	text-align: center;

  & ::v-deep(.canvas-container) {
    margin: 0 auto; //Centre the (FabricJS generated) canvas
  }

  & ::v-deep(.p-inputnumber) {
    padding: 0 15px;
  }

  & ::v-deep(.p-inputtext) {
    width: 100%; //Make sure the whole cell is used
    padding: 3px;
    font-size: 0.7rem !important;
    text-align: right;
  }

  & div {
    font-size: 0.9em;
  }
}

h4, h5 {
  margin: 3px 0 0 0;
}

canvas {
  width: 100%;
  height: 100px;
}
</style>