<template>
  <div class="main-wrapper">
    <design-parameters-box/>
    <ul>
      <li v-for="system in allDrainageSystems" :key="system.name" :class="getClasses(system)"
          @click="handleSystemClick(system)">
        <div>{{ system.name }}</div>
        <div>{{ system.load_class }}</div>
      </li>
    </ul>
    <div class="img-wrapper">
      <img alt="product image" id="channel-image" class="system" :src="url"/>
    </div>
  </div>
</template>

<script>
import {BASE_URL} from "@/constants";
import {mapGetters} from "vuex";
import DesignParametersBox from "../../DesignParametersBox";

const DEFAULT_URL = `${BASE_URL}/static/images/renders/when_ideas_flow_together.jpg`;

export default {
  name: "SystemSelection",
  components: {
    DesignParametersBox
  },
  setup() {
    return {
    }
  },
  data() {
    return {
      url: DEFAULT_URL,
    }
  },
  methods: {
    handleSystemClick(system) {
      this.$store.commit('setSelectedSystem', system)
    },
    getClasses: function (system) {
      const classes = [system.range];

      if (this.selectedDrainageSystem?.name === system.name) {
        //Pretend the currently selected channel group is being hovered
        classes.push(classes[0] + "-selected");
      }

      return classes;
    },
  },
  watch: {
    selectedDrainageSystem: {
      immediate: true,
      handler(system) {
        this.url = system ? `${BASE_URL}/static/images/renders/${system.product_image_name}` : DEFAULT_URL;
      }
    }
  },
  computed: {
    ...mapGetters({
      allDrainageSystems: 'normalDrainageSystems',
      selectedDrainageSystem: 'getDrainageSystem',
    }),
  },
}
</script>

<style scoped lang="scss">

.main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //width: 100%;
  padding: 0 0 0 0;
}

#channel-image {
  width: 700px;
}

ul {
  list-style-type: none;
  width: 350px;
  margin: 0;
  padding: 0;
}

li {
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.25rem 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin product-group($hover-colour) {
  background-color: white;
  color: black;
  font-family: "Interstate Light", "Trebuchet MS", Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 13px;

  &:hover, &-selected {
    color: white;
    //background-color: $hover-colour;
    background-color: var(--blue);
  }
}

.civils {
  @include product-group(#009ede);
}

.landscaping {
  @include product-group(#93c11c);
}

@media (max-width: 1900px) {
  #channel-image {
    width: 350px;
    height: auto;
  }
  ul {
    width: 24vw;
    margin: 0 1rem;
  }
}

</style>
