<template>
  <div id="drilling-at-last-element-wrapper">
    <Checkbox v-model="hasDrillingAtLast" :binary="true" :disabled="runChannels.length === 0 || isLChannel"
              id="drilling-at-last-element"/>
    <label class="header-label" :for="'drilling-at-last-element'">{{ $t('drillingAtLastElement') }}</label>
  </div>
  <div class="modification-section">
    <div>
      <div id="diameter-of-drilling">
        <div class="dropdown-wrapper">
          <div id="drilling-diameter-wrapper">
            <span style="padding-right: 0.5rem"
                  :style="hasDrillingAtLast ? {color: 'black'} : {color: 'lightgray'}">{{
              $t('diameterOfDrilling')
            }}</span>
            <div>
              <Dropdown v-model="drillingDiameter" :options="filteredDiameters"
                        id="drilling-diameter" :disabled="!hasDrillingAtLast || isLChannel"
                        class="drilling-diameter-dropdown">
                  <template #value="{value, placeholder}">
                    <span v-if="value">{{ value }} mm</span>
                    <span v-else>{{ placeholder }}</span>
                  </template>
                  <template #option="{option}">
                    {{ option }} mm
                  </template>
              </Dropdown>
            </div>
          </div>
          <div id="distance-beginning-center-drilling-wrapper">
            <span id="distance-from-center-label" :style="{color: hasDrillingAtLast ? 'black' : 'lightgray'}">{{
              $t('distanceEdgeToDrillingCenter')
            }}</span>
            <InputNumber
                :disabled="!hasDrillingAtLast || isLChannel"
                @focus="$event.target.select()"
                v-model="distanceEdgeToDrillingCenter"
                class="form-input"
                suffix=" mm"
                mode="decimal"
                :max="Math.floor(lastChannel.channel_length_mm - (drillingDiameter / 2) - 100)"
                :min="Math.ceil((drillingDiameter / 2) + 100)"
                inputStyle="fontSize: 0.7rem; width: 60px; padding:2px; textAlign: right; marginLeft: 0.5rem"/>
          </div>
        </div>
      </div>
      <div class="row" id="drilling-radio-buttons">
        <div class="radiobutton">
          <RadioButton name="drillingRadios" value="bottom" class="p-button-sm"
                       v-model="drillingRadios" :disabled="!hasDrillingAtLast || isLChannel"
                       id="bottom-axial-button"/>
          <label :for="'bottom-axial-button'"
                 class="radio-label"
                 :style="hasDrillingAtLast ? {color: 'black'} : {color: 'lightgray'}">{{ $t('bottomAxial') }}</label>
        </div>
        <div class="radiobutton">
          <RadioButton name="drillingRadios" value="leftWall" class="p-button-sm"
                       v-model="drillingRadios" :disabled="!hasDrillingAtLast || isLChannel"
                       id="left-side-wall-button"/>
          <label :for="'left-side-wall-button'"
                 class="radio-label"
                 :style="hasDrillingAtLast ? {color: 'black'} : {color: 'lightgray'}">{{ $t('leftSideWall') }}</label>
        </div>
        <div class="radiobutton">
          <RadioButton name="drillingRadios" value="rightWall" class="p-button-sm"
                       v-model="drillingRadios" :disabled="!hasDrillingAtLast || isLChannel"
                       id="right-side-wall-button"/>
          <label :for="'right-side-wall-button'"
                 class="radio-label" :style="hasDrillingAtLast ? {color: 'black'} : {color: 'lightgray'}">{{
            $t('rightSideWall')
          }}</label>
        </div>
      </div>
    </div>

    <div class="drilling-canvas-container" v-if="!isConcrete">
      <!-- DRILLING -->
      <drilling-at-last-element :drillingRadios="drillingRadios" :lastChannel="lastChannel"
                                :hasDrillingAtLast="hasDrillingAtLast"/>
    </div>
  </div>
</template>

<script>
import DrillingAtLastElement from "@/components/main_content/tabs_content/design_run/channels/DrillingAtLastElement";
import {mapGetters} from "vuex";

export default {
  name: "DischargeModification",
  components: {DrillingAtLastElement},
  data() {
    return {
      drillingDiameters: [
        50,
        75,
        110,
        125,
        160,
        200,
        225,
        250,
        315,
      ],
    }
  },
  computed: {
    ...mapGetters([
        'selectedRun',
    ]),
    filteredDiameters() {
      let maximum_hole_mm = this.lastChannel.width_mm;
      if (this.drillingRadios === 'leftWall' || this.drillingRadios === 'rightWall') {
        maximum_hole_mm = Math.min(maximum_hole_mm, this.lastChannel.max_downstream_water_depth_mm);
      }
      // modify to return 1 size larger than the nominal diameter
      const limit = this.drillingDiameters.findIndex(d => d >= maximum_hole_mm);
      return limit >= 0 ? this.drillingDiameters.slice(0, limit + 1) : this.drillingDiameters;
    },
    runChannels() {
      return this.$store.getters.getRunChannels
    },
    lastChannel() {
      return this.runChannels.at(-1)
    },
    isLChannel() {
      return !this.isConcrete && this.lastChannel.name.endsWith('L');
    },
    isConcrete() {
      return this.selectedRun.selectedSystem?.product_type === 'M';
    },
    hasDrillingAtLast: {
      get() {
        return this.selectedRun.hasDrillingAtLast;
      },
      set(value) {
        this.$store.commit('setHasDrillingAtLast', value);
      }
    },
    drillingDiameter: {
      get() {
        return this.selectedRun.drilling.hole_diameter_mm;
      },
      set(value) {
        this.$store.commit('setDrillingDiameterValue', value);
        const drillingRadius = value / 2;
        if (this.distanceEdgeToDrillingCenter < drillingRadius + 100) {
          this.distanceEdgeToDrillingCenter = Math.ceil(drillingRadius + 100);
        } else if (this.distanceEdgeToDrillingCenter > this.lastChannel.channel_length_mm - drillingRadius - 100) {
          this.distanceEdgeToDrillingCenter = Math.floor(this.lastChannel.channel_length_mm - drillingRadius - 100);
        }
      }
    },
    distanceEdgeToDrillingCenter: {
      get() {
        return this.selectedRun.drilling.hole_distance_mm;
      },
      set(value) {
        this.$store.commit('setDistanceEdgeToDrillingCenterValue', value);
      }
    },
    drillingRadios: {
      get() {
        return this.selectedRun.drilling.position;
      },
      set(value) {
        this.$store.commit('setDrillingAtLastPosition', value);
      }
    },
  },
  watch: {
    lastChannel(lastChannel) {
      if (!lastChannel) {
        return this.dischargeRadios = 'free-outflow'
      }
      const drillingRadius = this.drillingDiameter / 2;
      if (this.distanceEdgeToDrillingCenter > lastChannel.channel_length_mm - drillingRadius - 100) {
        this.distanceEdgeToDrillingCenter = Math.floor(lastChannel.channel_length_mm - drillingRadius - 100);
      }
    },
  },
  methods: {
  },
}
</script>

<style scoped>
.modification-section {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.dropdown-wrapper {
  margin: 1rem 0 0 0;
}

.drilling-diameter-dropdown {
  width: 100px;
  text-align: right;
  margin-left: 9rem;
}

.radio-label {
  margin: 0.5rem;
}

.radiobutton {
  margin: 0 29px 0.5rem;
  width: fit-content;
}

.p-radiobutton-disabled, .p-checkbox-disabled {
  cursor: default;
}

h4 {
  margin: 0;
}

.header-label {
  margin-left: 0.5rem;
  font-weight: bold;
}

#drilling-diameter-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 1.5rem 0 1rem;
  padding-left: 29px;
}

#distance-beginning-center-drilling-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 29px;
}

label, span {
  font-size: 0.7rem !important;
}

.drilling-canvas-container {
}

#drilling-radio-buttons {
  margin-bottom: 2rem
}

#drilling-at-last-element-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
</style>
