<template>
  <navbar/>
<!--  <main-wrapper :isHauratonUser="isHauratonUser"-->
<!--                @duplicate-run="handleDuplicateRun($event)"-->
<!--  />-->

<!--  <router-view></router-view>-->
</template>

<script>

// import MainWrapper from './components/MainWrapper.vue'
import Navbar from './components/Navbar.vue'


export default {
  name: 'App',
  components: {
    // MainWrapper,
    Navbar
  },
  beforeMount() {
    //Default the language to what the user's browser is set to use
    this.$i18n.locale = window.navigator.language;
  },
  methods: {
  },
  watch: {
  },
}
</script>

<style>
@import url('./assets/css/hauraton.css');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
}

</style>
