<template>
  <div class="wrapper">
    <div class="col">
      <!--   SOFTWARE FEATURES  -->
      <div class="card">
        <h2>{{ $t('softwareFeatures.title') }}</h2>
        <div class="versions">
          <div class="inline"><img src="@/assets/images/pdf_icon.svg" alt="PDF Icon" class="pdf-icon"><a
              href="/static/H_Features_DS_UK(11.22)_V4.pdf">{{ $t('softwareFeatures.uk') }}</a></div>
          <div class="inline"><img src="@/assets/images/pdf_icon.svg" alt="PDF Icon" class="pdf-icon"><a
              href="/static/H_Features_DS_EU(11.22).pdf">{{ $t('softwareFeatures.international') }}</a></div>
        </div>
      </div>
      <!--  FEEDBACK  -->
      <div class="card feedback">
        <h2>{{ $t('feedback.title') }}</h2>
        <p>{{ $t('feedback.description') }}</p>
        <p>{{ $t('feedback.europe&beyond') }}: TSE@hauraton.com</p>
        <p>{{ $t('feedback.uk') }}: TS-UK@hauraton.com</p>
        <h3>{{ $t('feedback.contactUs') }}</h3>
      </div>
    </div>
    <div class="col">
      <slot>
      <!--  LOG IN from  -->
      <template v-if="!showResetPasswordForm">
        <div class="card form">
          <h1>{{ $t('login.title') }}</h1>
          <p>{{ $t('login.alreadyHaveAccount') }}</p>
          <div class="input">
            <label :for="'username'">{{ $t('login.username') }}</label>
            <InputText id="username" type="text" v-model="username" autocomplete="username"/>
          </div>
          <div class="input">
            <label :for="'password'">{{ $t('login.password') }}</label>
            <InputText id="password" type="password" v-model="password" autocomplete="current-password" @keyup.enter="tryLogin"/>
          </div>
          <div class="input right">
            <Button class="p-button-sm" :label="$t('login.button')"
                    @click="tryLogin" :disabled="!username || !password"/>
          </div>
          <i18n-t scope="global" keypath="login.doNotHaveAccount" tag="p">
            <template #here>
              <router-link to="/sign-up">{{ $t('login.here') }}</router-link>
            </template>
          </i18n-t>
          <i18n-t scope="global" keypath="login.forgottenPassword" tag="p">
            <template #here>
              <span @click="showResetPasswordForm = true">{{ $t('login.here') }}</span>
            </template>
          </i18n-t>
        </div>
        <p class="card form">{{ $t('softwareFeatures.disclaimer') }}</p>
      </template>
      <!--   RESET PASSWORD form   -->
      <div class="card form" v-else>
        <h1>{{ $t('passwordReset.title') }}</h1>
        <p>{{ $t('passwordReset.description') }}</p>
        <div class="input">
          <label :for="'reset-email'">{{ $t('email') }}</label>
          <InputText type="text" v-model="email" id="reset-email" autocomplete="email"/>
        </div>
        <small class="input" v-show="resetMessage">
          {{ resetMessage }}
        </small>
        <div class="input right">
          <Button :label="$t('passwordReset.button')" class="p-button-sm"
                  @click="resetPassword" :disabled="!email"/>
        </div>
        <i18n-t scope="global" keypath="passwordReset.otherwiseLogIn" tag="div" class="row otherwise">
          <template #here>
            <span @click="showResetPasswordForm = false">{{ $t('login.here') }}</span>
          </template>
        </i18n-t>
      </div>
      </slot>
    </div>
    <div class="col">
      <!--  DESCRIPTION  -->
      <div class="card freedom">
        <h2>{{ $t('freedomAdvertBox.title') }}</h2>
        <p>{{ $t('freedomAdvertBox.description') }}</p>
        <h3>{{ $t('freedomAdvertBox.justThreeClicks') }}</h3>
      </div>
      <!--   SCREENSHOT   -->
      <img src="@/assets/images/pc_image.png" alt="" class="screenshot">
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import {mapMutations} from "vuex";
import {login, refresh, storedUserProfile} from "@/auth";
import {BASE_URL} from '@/constants';

export default {
  name: "LogInForm",
  data() {
    return {
      username: '',
      password: '',
      email: '',
      resetMessage: null,
      showResetPasswordForm: false
    }
  },
  async mounted() {
    if (document.cookie.split(';').some(cookie => cookie.trim().startsWith('session-state='))) {
      if (await refresh()) {
        //Existing refresh token is fine
        this.setUser(storedUserProfile());
      }
    } else {
      document.cookie = 'session-state=; SameSite=Strict;';
    }
  },
  methods: {
    ...mapMutations([
        'setUserDetails',
    ]),
    async tryLogin() {
      const {user, message} = await login(this.username, this.password);

      if (user) {
        this.setUser(user);
      } else {
        if (message) console.warn(message); //Probably display this somewhere?
        this.password = '';
      }
    },
    setUser(user) {
      this.setUserDetails(user);
      this.$i18n.locale = user.language ?? 'en';
    },
    async resetPassword() {
      this.resetMessage = null;
      const response = await fetch(`${BASE_URL}/auth/password/reset/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          email: this.email,
        }),
      });
      if (response.ok) {
        this.resetMessage = 'A password reset e-mail has been sent.';
      } else if (response.status === 400) {
        this.resetMessage = 'Please check the email address is valid.';
      } else {
        Sentry.captureMessage('Error requesting password reset', {
          extra: {
            type: 'fetch',
            status: response.status,
            response: await response.text(),
            email: this.email,
          },
        });
      }
    },
  },
}
</script>

<style scoped>

h1, ::v-slotted(h1) {
  text-align: center;
  margin-top: 0;
}

h2 {
  margin-top: 0;
}

h3 {
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  font-size: 0.8rem;
  width: 100%;
  font-family: "Interstate Light", "Trebuchet MS", Verdana, Geneva, Arial, Helvetica, sans-serif;
}

.pdf-icon {
  width: 1.5rem;
  margin-right: 1rem;
}

.inline {
  display: flex;
  align-items: center;
}

.card, ::v-slotted(.card) {
  padding: 2.5rem;
  border-radius: 5px;
  box-shadow: 2px 2px 10px grey;
  margin: 3rem auto;
  width: 30rem;
}

label {
  font-weight: bolder;
}

a {
  color: var(--blue);
}

.input, ::v-slotted(.input) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 1rem auto;
  max-width: 15rem;
}

::v-deep(.p-inputtext.p-component) {
  font-size: 0.8rem;
}

.versions {
  display: flex;
  justify-content: left;
  flex-direction: column;
  row-gap: 1rem;
}

.right {
  justify-content: right;
}

p {
  line-height: 1.2rem;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.screenshot {
  width: 30rem;
}

span {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
}

.freedom {
  width: 30rem;
}

.feedback {
}

.form, ::v-slotted(.form) {
  width: 22rem;
}

@media screen and (max-width: 1600px) {
  .card {
    font-size: 12px;
    width: 20rem;
    margin: 1rem auto;
  }

  .screenshot {
    margin: 0 auto;
    border-radius: 5px;
  }

  .freedom, .screenshot {
    width: 20rem;
  }
}
</style>
