<template>
  <TabView :activeIndex="tabIndex" id="tab-wrapper" @tab-change="handleTabChange($event.index)" lazy>
    <TabPanel :header="$t('systemSelection')" v-if="!isAdvancedMode">
      <system-selection/>
    </TabPanel>
    <TabPanel :disabled="!isSelectedRunCalculated && !isAdvancedMode">
      <template #header><!-- The :header is used as :key if provided, which means renaming the tab would reload it -->
        <!-- PrimeVue uses the slot slightly naughtily, so it works despite TabPanel not having a header slot -->
        <span class="p-tabview-title">{{ $t('designRunsLabel') }} - {{ selectedRunName }}</span>
      </template>
      <div :class="{'tight-top': isAdvancedMode}">
        <design-run v-if="isAdvancedMode"
                    :selectedAction="selectedAction" @set-action="$emit('update:selectedAction', $event)"/>
        <ordinary-user-design-run v-else/>
      </div>
    </TabPanel>
    <TabPanel :header="$t('materialsListLabel')" :disabled="!isSelectedRunCalculated">
      <material-list/>
    </TabPanel>
    <TabPanel :header="$t('documentsLabel')" :disabled="!isSelectedRunCalculated">
      <Documents/>
    </TabPanel>
  </TabView>
</template>

<script>
import {mapGetters} from "vuex";
import DesignRun from './tabs_content/design_run/DesignRun.vue'
import SystemSelection from "./tabs_content/system_selection/SystemSelection";
import OrdinaryUserDesignRun from "./tabs_content/ordinary_user_design_run/OrdinaryUserDesignRun";
import Documents from "@/components/main_content/tabs_content/documents/Documents";
import MaterialList from "./tabs_content/MaterialList";


export default {
  props: ['selectedAction', 'isAdvancedMode'],
  emits: ['update:selectedAction'],
  name: "HauratonUserTabs",
  components: {
    MaterialList,
    OrdinaryUserDesignRun,
    SystemSelection,
    DesignRun,
    Documents,
  },
  data() {
    return {
    }
  },
  methods: {
    handleTabChange(index) {
      this.$store.commit('setTabIndex', index)
    },
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      'tabIndex',
      'selectedRunName',
      'isSelectedRunCalculated',
    ]),
  },
}
</script>
<style scoped lang="scss">
#tab-wrapper {
  width: 1575px;
  display: flex;
  flex-direction: column;
}

.p-tabview > ::v-deep(.p-tabview-panels) {
  /* Remove the 1rem default padding from all sides but the left */
  padding: 0 0 0 1rem;
  flex-grow: 1;

  & > .p-tabview-panel {
    height: 100%;

    & > :not(.tight-top):first-child {
      /* Add top padding in to the first element of the tab, if that element doesn't have the tight-top class */
      padding-top: 1rem;
    }
  }
}

.p-tabview ::v-deep(.p-tabview-nav) {
  background: none;  //Remove the (white) background for the tab bar

  & li .p-tabview-nav-link, li.p-highlight .p-tabview-nav-link {
    background: none; //Remove the background from the individual tab buttons
  }
}

.tight-top {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1900px) {
  #tab-wrapper {
    height: 80vh;
  }
}
</style>
