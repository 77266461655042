<template>
  <div id="design-parameters-wrapper">
    <h3>{{ $t('designParameters') }}</h3>
    <div class="form-row" id="channel-location-wrapper">
      <span class="p-float-label">
      <InputText type="text" v-model="channelRunLocation" :disabled="(selectedSystem === null) && !isAdvanced"
                 id="channel-run-location" :placeholder=" $t('channelRunLocation')"/>
      </span>
      <Tooltip :text="$t('channelRunLocationTT')"/>
    </div>
    <div class="form-row" v-if="this.$store.state.user?.country?.region === 'UK'">
      <div class="input">
        <label class="on-its-own">{{ $t('rainfall.map') }}</label>
        <Button id="raindrop" @click="displayUKRainfallMap = true">
          <img :src="require('@/../../drainage/static/images/icons/raindrop.png')" alt="raindrop"
               id="raindrop-icon">
        </Button>
      </div>
      <Tooltip :text="$t('rainfall.mapTT')"/>
    </div>
    <div class="form-row">
      <div class="input">
        <label class="on-its-own">{{ $t('rainfall.intensity') }}</label>
        <InputNumber
            @focus="$event.target.select()"
            @blur="onRainfallSet"
            v-model="rainfallIntensity"
            id="rainfall-intensity"
            class="form-input"
            :suffix="` ${units.name}`"
            mode="decimal"
            :locale="$i18n.locale"
            :min="0" :minFractionDigits="2" :maxFractionDigits="2"/>
      </div>
    </div>
    <div class="form-row" v-if="!isAdvanced">
      <ChannelGroupSelector v-model="selectedSystem" :options="drainageSystems" :key="!!drainageSystems"
                            :placeholder="$t('selectASystem')" focus @change="handleSystemSelect"/>
    </div>

    <template v-if="!isAdvanced">
      <!--  Extra fields to be rendered conditionally -->
      <div class="form-row" v-if="needsLoading">
        <div id="selected-loading">
          <LoadingSelector v-model:selected-loading="selectedLoading" :disabled="selectedSystem === null"
                           :available-loadings="selectedSystem?.loading_classes"
                           :class="{'p-invalid': selectedSystem && !selectedLoading}"/>
          <p v-if="selectedSystem === null" class="error">{{ $t('selectChannelFirst') }}</p>
        </div>
      </div>
      <div class="form-row" v-if="needsGrating">
        <div id="selected-grating">
          <Dropdown v-model="selectedGrating" :options="selectedSystem.gratings"
                    :placeholder="$t('grating.pleaseSelect')"/>
        </div>
      </div>
    </template>

    <div class="form-row" v-if="!isPointCatchment">
      <label class="on-its-own">{{ $t('catchment.label') }}</label>
      <div class="radios">
        <div class="radio-button">
          <RadioButton name="catchmentRadio" value="irregular" id="irregular-catchment"
                       v-model="catchmentShape" @change="onSelectIrregularArea(channelLength, catchmentArea)"
                       :disabled="!selectedSystem && !isAdvanced"/>
          <label :for="'irregular-catchment'" class="radio-label">{{ $t('catchment.irregular') }}</label>
        </div>
        <div class="radio-button">
          <RadioButton name="catchmentRadio" value="uniform" id="uniform-catchment"
                       v-model="catchmentShape" @change="onSelectUniformArea()"
                       :disabled="!selectedSystem && !isAdvanced"/>
          <label :for="'uniform-catchment'" class="radio-label">{{ $t('catchment.uniform') }}</label>
        </div>
      </div>
      <Tooltip>
        <i18n-t scope="global" keypath="catchment.tooltip">
          <br/>
        </i18n-t>
      </Tooltip>
    </div>

    <div class="form-row" v-if="!isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('channelLength') }}</label>
        <InputNumber
            @focus="$event.target.select()"
            v-model="channelLength" :min="0"
            mode="decimal" :minFractionDigits="1" :max-fraction-digits="1"
            :disabled="(selectedSystem === null || isPointCatchment) && !isAdvanced"
            class="form-input" :class="{'warning': channelLength > 500, 'p-invalid': channelLength > maxChannelLength}"
            :locale="$i18n.locale"
            suffix=" m"/>
      </div>
      <i class="pi pi-exclamation-triangle" :title="$t('lengthWarning.title')"
         v-show="channelLength > 500 && channelLength <= maxChannelLength" @click="displayLengthWarning = true"/>
      <i class="pi pi-exclamation-circle" :title="$t('lengthError.title')"
         v-show="channelLength > maxChannelLength" @click="displayLengthError = true"/>
      <Tooltip :text="$t('channelLengthTT')"/>
    </div>
    <div class="form-row" v-if="!isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('catchmentDepth') }}</label>
        <InputNumber
            @focus="$event.target.select()"
            v-model="catchmentDepth" :min="0" :minFractionDigits="1" :maxFractionDigits="3"
            :disabled="(selectedSystem === null) && !isAdvanced"
            class="form-input" :locale="$i18n.locale"
            suffix=" m"/>
      </div>
      <Tooltip :text="$t('catchmentDepthTT')"/>
    </div>
    <div class="form-row">
      <div class="input">
        <label class="on-its-own">{{ $t('catchmentArea') }}</label>
        <InputNumber
            @focus="$event.target.select()"
            v-model="catchmentArea" :min="0"
            :disabled="(selectedSystem === null) && !isAdvanced"
            class="form-input" :class="{'p-invalid': catchmentArea >= 100000}" :locale="$i18n.locale"
            suffix=" m²"/>
      </div>
      <i class="pi pi-exclamation-circle" :title="$t('catchmentWarning.title')"
         v-show="catchmentArea >= 100000" @click="displayCatchmentWarning = true"/>
      <Tooltip :text="$t('catchmentAreaTT')"/>
    </div>
    <div class="form-row" v-if="!isAdvanced && catchmentShape === 'irregular'">
      <div class="input">
        <label class="on-its-own">{{ $t('catchment.editShape') }}</label>
        <span class="form-input"><!-- Mirror the styling of the numeric inputs -->
          <Button style="width: 70px; padding: 0; display: inline-block;" :disabled="!channelLength || !catchmentArea"
                  @click="displayIrregularCatchment = true" :label="$t('catchment.open')"/>
        </span>
      </div>
    </div>

    <!-- <div class="form-row" v-if="selectedSystem?.slug === 'recyfixhicap'"> -->
    <div class="form-row" v-if="!isPointCatchment">
      <label class="on-its-own">{{ $t('calculationMethod.label') }}</label>
      <div class="radios">
        <div class="radio-button">
          <RadioButton name="calculationMethodRadio" value="GVF" v-model="calculationMethod"
                       class="p-button-sm" id="use-gvf"/>
          <label :for="'use-gvf'" class="radio-label">{{ $t('calculationMethod.gvf') }}</label>
        </div>
        <div class="radio-button">
          <RadioButton name="calculationMethodRadio" value="HRW" v-model="calculationMethod"
                       class="p-button-sm" id="use-hrw"/>
          <label :for="'use-hrw'" class="radio-label">{{ $t('calculationMethod.hrw') }}</label>
        </div>
      </div>
      <Tooltip>
        <i18n-t scope="global" keypath="calculationMethod.tooltip">
          <br/>
        </i18n-t>
      </Tooltip>
    </div>

    <div class="form-row" v-if="!isPointCatchment">
      <label class="on-its-own">{{ $t('slopeSetting.label') }}</label>
      <div class="radios">
        <div class="radio-button">
          <RadioButton name="slopeRadio" value="slope" v-model="slopeSetting"
                       class="p-button-sm" id="use-slope"/>
          <label :for="'use-slope'" class="radio-label">{{ $t('slopeSetting.useSlope') }}</label>
        </div>
        <div class="radio-button">
          <RadioButton name="slopeRadio" value="elevation" v-model="slopeSetting"
                       class="p-button-sm" id="use-elevation"/>
          <label :for="'use-elevation'" class="radio-label">{{ $t('slopeSetting.useElevation') }}</label>
        </div>
      </div>
      <Tooltip>
        <i18n-t scope="global" keypath="slopeSetting.tooltip">
          <br/>
        </i18n-t>
      </Tooltip>
    </div>
    <div class="form-row" v-if="!isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('longitudinalSlope') }}</label>
        <InputNumber
            @focus="$event.target.select()"
            v-model="longitudinalSlope"
            :disabled="slopeSetting !== 'slope' || (selectedSystem === null || isPointCatchment) && !isAdvanced"
            class="form-input" :class="{warning: wentSuperCritical, 'p-invalid': longitudinalSlope > maxSlopeAngle}"
            suffix=" %"
            :minFractionDigits="1" :max-fraction-digits="2"
            mode="decimal" :min="0"
            :locale="$i18n.locale"
            :step="0.1"/>
      </div>
      <i class="pi pi-exclamation-triangle" :title="$t('superCriticalWarning.title')"
         v-show="wentSuperCritical" @click="showSuperCriticalWarning"/>
      <Tooltip :text="$t('longitudinalSlopeTT')"/>
    </div>
    <div class="form-row" v-if="!isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('elevationChange.label') }}</label>
        <InputNumber
            v-model="upperElevation"
            :disabled="slopeSetting !== 'elevation' || (selectedSystem === null || isPointCatchment) && !isAdvanced"
            class="form-input" :class="{'p-invalid': upperElevation < lowerElevation}"
            suffix=" m" :locale="$i18n.locale"
            mode="decimal" :min="0" :min-fraction-digits="1" :max-fraction-digits="2"
            @focus="$event.target.select()"/>
      </div>
      <abbr :title="$t('elevationChange.head')">{{ $t('elevationChange.head')[0] }}</abbr>
      <div style="width: 1rem"><!-- More positioning ---></div>
      <Tooltip>
        <i18n-t scope="global" keypath="elevationChange.tooltip">
          <br/>
        </i18n-t>
      </Tooltip>
    </div>
    <div class="form-row" v-if="!isPointCatchment">
      <div class="input">
        <label class="on-its-own"><!-- Just here for positioning the input --></label>
        <InputNumber
            v-model="lowerElevation"
            :disabled="slopeSetting !== 'elevation' || (selectedSystem === null || isPointCatchment) && !isAdvanced"
            class="form-input" :class="{'p-invalid': upperElevation < lowerElevation}"
            suffix=" m" :locale="$i18n.locale"
            mode="decimal" :min="0" :min-fraction-digits="1" :max-fraction-digits="2"
            @focus="$event.target.select()"/>
      </div>
      <abbr :title="$t('elevationChange.discharge')">{{ $t('elevationChange.discharge')[0] }}</abbr>
      <i class="pi pi-exclamation-circle" :title="$t('elevationWarning.title')"
          v-if="longitudinalSlope > maxSlopeAngle" @click="displayElevationWarning = true"/>
      <i class="pi pi-exclamation-circle" :title="$t('elevationUphillWarning.title')"
          v-else-if="upperElevation < lowerElevation" @click="displayUphillWarning = true"/>
      <i v-else style="width: 1rem"><!-- More positioning ---></i>
      <div style="width: 1rem"><!-- Positioning where the tooltip would go ---></div>
    </div>
    <div class="form-row" v-if="catchmentShape !== 'irregular'">
      <PermeabilitySelector v-model:selected-permeability="areaFill"
                            v-model:permeability-value="permeabilityValue"
                            :disabled="(selectedSystem === null) && !isAdvanced"
      />
      <Tooltip :text="$t('selectAFillTT')"/>
    </div>
    <div class="form-row" v-if="!isPointCatchment">
      <label class="on-its-own">{{ $t('configuration') }}</label>
      <div class="radios">
        <div class="radio-button">
          <RadioButton name="configurationRadio" :value="true" class="p-button-sm" v-model="stepped" id="stepped"
                       :disabled="(!selectedSystem || isPointCatchment || !selectedSystem.canStep) && !isAdvanced"/>
          <label :for="'stepped'"
                 class="radio-label">{{ $t('stepped') }}</label>
        </div>
        <div class="radio-button">
          <RadioButton name="configurationRadio" :value="false" class="p-button-sm" v-model="stepped" id="single-size"
                       :disabled="!isAdvanced ? (selectedSystem === null || isPointCatchment) : advancedChannels.length > 1"/>
          <label :for="'single-size'" class="radio-label">{{
              $t('single')
            }}</label>
        </div>
      </div>
      <Tooltip>
        <i18n-t scope="global" keypath="configurationTT">
          <br/>
        </i18n-t>
      </Tooltip>
    </div>
    <div class="form-row" v-if="!isAdvanced && selectedSystem?.slug === 'recyfixhicap'">
      <div class="input">
        <label class="on-its-own">{{ $t('controlledDischarge.label') }}</label>
        <InputNumber
            :model-value="controlledDischarge"
            class="form-input"
            suffix=" m" :locale="$i18n.locale"
            mode="decimal" :min="0" :min-fraction-digits="1" :max-fraction-digits="2"
            :readonly="true" :disabled="stepped || longitudinalSlope > 0"
            v-tooltip.top="{value: $t('controlledDischarge.stepWarning'), disabled: !stepped && longitudinalSlope <= 0}"
            @focus.capture.stop="displayControlledDischarge = true"/>
      </div>
      <Tooltip>
        <i18n-t scope="global" keypath="controlledDischarge.tooltip"><br/></i18n-t>
      </Tooltip>
    </div>
    <div class="form-row" v-if="!isAdvanced && selectedSystem?.minimumSizeOptions">
      <div class="input">
        <label class="on-its-own">{{ $t('minimumChannelSize') }}</label>
        <span class="form-input">
          <Dropdown class="input-dropdown" v-model="minimumChannelSize" :options="selectedSystem.minimumSizeOptions"
                    option-value="slug" option-label="short_name">
            <template #option="{option: channel}">{{ channel.name }}</template>
          </Dropdown>
        </span>
      </div>
      <Tooltip :text="$t('minimumChannelSizeTT')"/>
    </div>
    <div class="form-row" v-if="isPointCatchment">
      <Dropdown v-model="pipeDiameter" :options="Object.keys(selectedSystem.pipeDiameters)" @change="checkExtension"
                :placeholder="`Select ${$t('pipeDiameter')}`">
        <template #value="label">{{ $t('pipeDiameter') }}: {{ label.value }} mm</template>
      </Dropdown>
      <Tooltip :text="$t('pipeDiameterTT')"/>
    </div>
    <!--<div class="form-row" v-if="isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('pipeLength') }}</label>
        <InputNumber
            @focus="$event.target.select()"
            v-model="pipeLength"
            :disabled="selectedSystem === null || !isPointCatchment"
            class="form-input" :locale="$i18n.locale"
            suffix=" m"/>
      </div>
      <Tooltip :text="$t('pipeLengthTT')"/>
    </div>
    <div class="form-row" v-if="isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('pipeSlope') }}</label>
        <InputNumber class="form-input"
                     :disabled="selectedSystem === null || !isPointCatchment"
                     @focus="$event.target.select()"
                     v-model="pipeSlope" suffix=" %" :locale="$i18n.locale"
                     mode="decimal" :min="0" :max="3.3" :step="0.1"
                     :minFractionDigits="1" :max-fraction-digits="2"/>
      </div>
      <Tooltip :text="$t('pipeSlopeTT')"/>
    </div>-->
    <div class="form-row" v-if="isPointCatchment">
      <div class="switch-wrapper">
        <label class="on-its-own">{{ $t('extensionHat') }}</label>
        <InputSwitch v-model="hasExtensionHat" :disabled="!selectedSystem.pipeDiameters[pipeDiameter]"/>
      </div>
      <Tooltip :text="$t('extensionHatTT')"/>
    </div>
    <div class="form-row" v-if="isPointCatchment">
      <div class="input">
        <label class="on-its-own">{{ $t('waterInflow') }}</label>
        <InputNumber class="form-input"
                     @focus="$event.target.select()"
                     v-model="waterInflow" suffix=" l/s" :locale="$i18n.locale"
                     mode="decimal" :min="0"
                     :minFractionDigits="1" :max-fraction-digits="2"/>
      </div>
      <Tooltip :text="$t('waterInflowTT')"/>
    </div>
    <u-k-rainfall-map v-if="displayUKRainfallMap"
                      @closeUKRainfallMap="closeRainfallMap($event)"/>
    <default-rainfall-dialog :displayDefaultRainfallDialog="displayDefaultRainfallDialog"
                             @closeDefaultRainfallDialog="closeRainfallMap(false)"/>
    <Dialog :header="$t('catchment.title')" :modal="true" :dismissableMask="true"
            v-model:visible="displayIrregularCatchment">
      <irregular-catchment :channelLength_m="channelLength" :catchmentArea_sqm="catchmentArea"/>
    </Dialog>
    <ControlledDischarge v-model:display="displayControlledDischarge" :autoZIndex="false"
                         @displayRainfallMap="stackRainfallMap"/>
    <Dialog :header="$t('lengthWarning.title')" v-model:visible="displayLengthWarning" :closable="false">
      {{ $t('lengthWarning.message', {excess: channelLength - 500}) }}
      <template #footer>
        <Button :label="$t('ok')" icon="pi pi-check" @click="displayLengthWarning = false" autofocus/>
      </template>
    </Dialog>
    <Dialog :header="$t('lengthError.title')" v-model:visible="displayLengthError" :closable="false">
      {{ $t('lengthError.message', {method: calculationMethod, limit: maxChannelLength, excess: channelLength - maxChannelLength}) }}
      <template #footer>
        <Button :label="$t('ok')" icon="pi pi-check" @click="displayLengthError = false" autofocus/>
      </template>
    </Dialog>
    <Dialog :header="$t('catchmentWarning.title')" v-model:visible="displayCatchmentWarning" :closable="false">
      {{ $t('catchmentWarning.message', {excess: catchmentArea - 99999}) }}
      <template #footer>
        <Button :label="$t('ok')" icon="pi pi-check" @click="displayCatchmentWarning = false" autofocus/>
      </template>
    </Dialog>
    <TooSlopyWarning v-model:display="displaySlopeWarning"/>
    <Dialog :header="$t('elevationWarning.title')" v-model:visible="displayElevationWarning" :closable="false">
      <i18n-t scope="global" keypath="elevationWarning.message">
        <br/>
      </i18n-t>
      <template #footer>
        <Button :label="$t('ok')" icon="pi pi-check" @click="displayElevationWarning = false" autofocus/>
      </template>
    </Dialog>
    <Dialog :header="$t('elevationUphillWarning.title')" v-model:visible="displayUphillWarning" :closable="false">
      <i18n-t scope="global" keypath="elevationUphillWarning.message">
        <br/>
      </i18n-t>
      <template #footer>
        <Button :label="$t('elevationUphillWarning.swap')" icon="pi pi-sort-alt" @click="swapElevation"/>
        <Button :label="$t('ok')" icon="pi pi-check" @click="displayUphillWarning = false"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import {mapGetters, mapActions} from "vuex";
import UKRainfallMap from "./tabs_content/UKRainfallMap";
import DefaultRainfallDialog from "./tabs_content/DefaultRainfallDialog";
import ChannelGroupSelector from "@/components/main_content/tabs_content/design_run/ChannelGroupSelector";
import LoadingSelector from "@/components/main_content/tabs_content/design_run/LoadingSelector";
import ControlledDischarge from "@/components/main_content/tabs_content/design_run/ControlledDischarge";
import PermeabilitySelector from "@/components/main_content/tabs_content/design_run/PermeabilitySelector";
import IrregularCatchment from "@/components/main_content/tabs_content/design_run/catchment/IrregularCatchment";
import Tooltip from "@/components/main_content/tabs_content/design_run/Tooltip";
import TooSlopyWarning from "@/components/main_content/tabs_content/design_run/TooSlopyWarning";

export default {
  name: "DesignParametersBox",
  components: {
    ControlledDischarge, DefaultRainfallDialog, UKRainfallMap, IrregularCatchment,
    ChannelGroupSelector, LoadingSelector, PermeabilitySelector, Tooltip, TooSlopyWarning,
  },
  props: ['isAdvanced'],
  inject: ['doCalculate', 'showSuperCriticalWarning'],
  data() {
    return {
      /** When dialogs want other dialogs, but also might want to reopen themselves */
      dialogStack: [],
      displayUKRainfallMap: false,
      displayDefaultRainfallDialog: false,
      displayIrregularCatchment: false,
      displayControlledDischarge: false,
      displayLengthWarning: false,
      displayLengthError: false,
      displayCatchmentWarning: false,
      displaySlopeWarning: false,
      displayElevationWarning: false,
      displayUphillWarning: false,
    }
  },
  methods: {
    ...mapActions([
      'getProductGroups',
    ]),
    handleSystemSelect() {
      this.$store.commit('setSelectedSystem', this.selectedSystem);
    },
    onRainfallSet() {
      if (this.selectedRun.first) {//Only for the first/original run
        this.$nextTick(() => {//Blur will trigger just before the store updates, so we'll wait a tick for that first
          const defaultRainfall = this.$store.getters.getRainfallDefaults.rainfall_mm_per_hr;
          //Avoid using this.rainfallIntensity as that might not be in the right units
          const currentRainfall = this.selectedRun.rainfall.rainfall_mm_per_hr;

          if (defaultRainfall !== currentRainfall) {
            this.$store.commit('setRainfallDefaults', {
              rainfall_mm_per_hr: currentRainfall,
              override: true,
            });
          }
        });
      }
    },
    async onSelectIrregularArea(channelLength_m, catchmentArea_m2) {
      await this.$store.dispatch('generateIrregularCatchment', {
        channelLength_m,
        catchmentArea_m2,
      });
    },
    onSelectUniformArea() {
      this.$store.commit('setCatchmentArea', {//Update the catchment depth according to the length and catchment area
        id: this.selectedRunId,
        catchmentArea: this.catchmentArea,
      });
    },
    closeRainfallMap(changedDefault) {
      this.displayUKRainfallMap = false;
      if (changedDefault) {
        this.displayDefaultRainfallDialog = true;
      } else {
        this.displayDefaultRainfallDialog = false;
        this.dialogStack.shift()?.();
      }
    },
    stackRainfallMap() {
      this.displayControlledDischarge = false;
      this.dialogStack.unshift(() => this.displayControlledDischarge = true);
      this.displayUKRainfallMap = true;
    },
    checkExtension() {
      if (this.hasExtensionHat && !this.selectedSystem.pipeDiameters[this.pipeDiameter]) {
        this.hasExtensionHat = false; //No extension hat available
      }
    },
    swapElevation() {
      [this.upperElevation, this.lowerElevation] = [this.lowerElevation, this.upperElevation];
      this.displayUphillWarning = false;
    },
  },
  mounted() {
    this.getProductGroups();
    this.$watch(() => [
      this.selectedLoading,
      this.channelLength,
      this.catchmentArea,
      this.channelRunLocation,
      this.rainfallIntensity,
      this.selectedSystem,
      this.permeabilityValue,
      this.longitudinalSlope,
      this.stepped,
      this.controlledDischarge,
      this.minimumChannelSize,
      this.pipeSlope,
      this.pipeLength,
      this.pipeDiameter,
      this.hasExtensionHat,
      this.waterInflow,
    ], () => {
      this.$store.commit('setHasInputChanged', true)
    });
  },
  created() {
    //Watched properties whose changes only apply when the run doesn't change too:
    for (const [name, watcher] of Object.entries({
      stepped(current, old) {
        if (current !== old && this.isSelectedRunCalculated) {
          //If the channel's slope is changed, having previously calculated, immediately calculate again
          this.doCalculate();
        }
      },
      hasExtensionHat(current, old) {
        if (current !== old && this.isSelectedRunCalculated) {
          //If the extension hat is added/removed, having previously calculated, immediately calculate again
          this.doCalculate();
        }
      },
      async selectedSystem(current, old) {
        //console.debug("System change", old, '=>', current);
        if (current?.slug === old?.slug) return;
        Sentry.addBreadcrumb({
          category: 'input',
          message: `Changed channel group from ${old?.slug} to ${current?.slug}`,
          level: Sentry.Severity.Info,
        });

        if (current.product_type !== 'M' && !current.loading_classes.includes(this.selectedLoading)) {
          // Pick the biggest loading as a default, if the current loading isn't an option
          this.selectedLoading = current.loading_classes.reduce((maxLoading, loading) => {
            return loading.substring(1) > maxLoading.substring(1) ? loading : maxLoading;
          });
        }

        if (this.stepped && !current.canStep) {
          this.stepped = false; //Don't allow a design to be stepped if it can't be
        }

        if (current.slug === 'recyfixhicap') {
          this.stepped = true; //HICAP runs default to being stepped
        } else if (old?.slug === 'recyfixhicap') {
          this.$store.commit('resetAccessories'); //Clear HICAP accessories away
        }

        if (!current.minimumChannelSize || !(this.minimumChannelSize in current.minimumChannelSize)) {
          this.minimumChannelSize = ''; //Don't try enforcing a minimum channel size which doesn't exist
        }

        if (current.product_type === 'P') {
          if (!(this.pipeDiameter in current.pipeDiameters)) {
            this.pipeDiameter = Math.min(...Object.keys(current.pipeDiameters));
          }
          this.checkExtension();
        }

        if (current.product_type !== old?.product_type) {
          // Reset other type values
          switch (current.product_type) {
            case 'P':
              if (this.catchmentShape === 'irregular') {
                this.catchmentShape = 'uniform';
              }
              this.$store.commit('setChannelLength', {id: this.selectedRunId, channelLength: 0})
              this.$store.commit('setGroundSlope', {id: this.selectedRunId, groundSlope: 0})
              break
            case 'C':
              this.$store.commit('setPipeLength', 0)
              this.$store.commit('setPipeDiameter', 0)
              break;
          }
        } else if (this.isSelectedRunCalculated) {
          // If the channel group is changed, having previously calculated, immediately calculate again
          this.doCalculate();
        }
      }
    })) {
      this.$watch(() => [this[name], this.selectedRunId], ([current, currentID], [old, oldID], ...args) => {
        if (currentID !== oldID) return; //Changed run, ignore any change to the watched property
        watcher.call(this, current, old, ...args);
      });
    }
  },
  computed: {
    ...mapGetters({
      drainageSystems: 'normalDrainageSystems',
      units: 'projectRainfallUnits',
      advancedChannels: 'getRunChannels',
    }),
    ...mapGetters([
      'selectedRun',
      'selectedRunId',
      'isSelectedRunCalculated',
      'needsGrating',
      'needsLoading',
      'controlledDischarge',
      'wentSuperCritical',
    ]),
    isPointCatchment() {
      return this.selectedSystem?.product_type === 'P';
    },
    channelRunLocation: {
      get() {
        return this.selectedRun.location;
      },
      set(value) {
        this.$store.commit('setRunLocation', {
          id: this.selectedRunId,
          channelRunLocation: value,
        });
      }
    },
    rainfallIntensity: {
      /** @returns {number} The rainfall intensity in mm/h */
      get() {
        return this.selectedRun.rainfall.rainfall_mm_per_hr * this.units.scale;
      },
      /** @param {number} value The rainfall intensity in mm/h */
      set(value) {
        this.$store.commit('setRainfallIntensity', {
          id: this.selectedRunId,
          rainfallIntensity: value / this.units.scale,
        });
      }
    },
    selectedSystem: {
      get() {
        return this.$store.getters.getDrainageSystem
      },
      set(value) {
        this.$store.commit('setSelectedSystem', value)
      }
    },
    catchmentShape: {
      get() {
        return this.selectedRun.catchmentShape;
      },
      set(catchmentShape) {
        this.$store.commit('setCatchmentShape', {catchmentShape});
      }
    },
    selectedLoading: {
      get() {
        return this.$store.getters.getLoading;
      },
      set(value) {
        this.$store.commit('setLoading', value);
      }
    },
    selectedGrating: {
      get() {
        return this.selectedRun.grating;
      },
      set(value) {
        this.$store.commit('setGrating', value);
      }
    },
    maxChannelLength() {
      return this.selectedRun.maxChannelLength;
    },
    channelLength: {
      get() {
        return this.selectedRun.channel_length_m;
      },
      set(value) {
        if (value === this.channelLength) return; //No change
        if (this.catchmentShape === 'irregular') this.onSelectIrregularArea(value, this.catchmentArea);
        this.$store.commit('setChannelLength', {
          id: this.selectedRunId,
          channelLength: !value ? this.$store.getters.getDefaultRun.channel_length_m : value,
        });
        if (value > this.maxChannelLength) this.displayLengthError = true;
        //else if (value > 500) this.displayLengthWarning = true;
      }
    },
    catchmentDepth: {
      get() {
        return this.selectedRun.catchment_depth_m;
      },
      set(value) {
        if (value === this.catchmentDepth) return; //No change
        this.$store.commit('setCatchmentDepth', value);
        if (this.catchmentShape === 'irregular') this.onSelectIrregularArea(this.channelLength, this.catchmentArea);
      }
    },
    catchmentArea: {
      get() {
        return this.selectedRun.drainage_area_m2;
      },
      set(value) {
        if (value === this.catchmentArea) return; //No change
        if (this.catchmentShape === 'irregular') this.onSelectIrregularArea(this.channelLength, value);
        this.$store.commit('setCatchmentArea', {
          id: this.selectedRunId,
          catchmentArea: !value ? this.$store.getters.getDefaultRun.drainage_area_m2 : value,
        });
      }
    },
    calculationMethod: {
      get() {
        return this.selectedRun.calculation_method;
      },
      set(calculationMethod) {
        this.$store.commit('setCalculationMethod', {
          id: this.selectedRunId,
          calculationMethod,
        });
        //Max channel length and slope angle both depend on the calculation method
        if (this.channelLength > this.maxChannelLength) this.displayLengthError = true;
        if (this.longitudinalSlope > this.maxSlopeAngle) {
          this.displaySlopeWarning = true;
          //Only change the slope if not calculating from the elevation
          if (this.slopeSetting !== 'elevation') this.longitudinalSlope = this.maxSlopeAngle;
        }
      }
    },
    slopeSetting: {
      get() {
        return this.selectedRun.slopeSetting;
      },
      set(value) {
        this.$store.commit('setSlopeSetting', {
          id: this.selectedRunId,
          slopeSetting: value,
        });
      }
    },
    maxSlopeAngle() {
      return this.selectedRun.maxSlopeAngle;
    },
    longitudinalSlope: {
      get() {
        return this.selectedRun.ground_slope;
      },
      set(value) {
        if (value > this.maxSlopeAngle) {
          this.displaySlopeWarning = true;
          value = this.maxSlopeAngle;
        }
        this.$store.commit('setGroundSlope', {
          id: this.selectedRunId,
          groundSlope: !value ? this.$store.getters.getDefaultRun.ground_slope : value,
        });
      }
    },
    upperElevation: {
      get() {
        return this.selectedRun.upperElevation;
      },
      set(value) {
        const shownWarning = this.longitudinalSlope > this.maxSlopeAngle;
        this.$store.commit('setUpperElevation', {
          id: this.selectedRunId,
          elevation: value,
        });
        if (!shownWarning && this.longitudinalSlope > this.maxSlopeAngle && value > 0 && this.lowerElevation > 0) this.displayElevationWarning = true;
      }
    },
    lowerElevation: {
      get() {
        return this.selectedRun.lowerElevation;
      },
      set(value) {
        const shownWarning = this.longitudinalSlope > this.maxSlopeAngle;
        this.$store.commit('setLowerElevation', {
          id: this.selectedRunId,
          elevation: value,
        });
        if (!shownWarning && this.longitudinalSlope > this.maxSlopeAngle && this.upperElevation > 0 && value > 0) this.displayElevationWarning = true;
      }
    },
    stepped: {
      get() {
        return this.selectedRun.stepped;
      },
      set(value) {
        this.$store.commit('setStepped', {
          id: this.selectedRunId,
          isStepped: value,
        });
      }
    },
    areaFill: {
      get() {
        return this.selectedRun.permeability_description;
      },
      set(value) {
        this.$store.commit('setPermeabilityName', value);
      }
    },
    permeabilityValue: {
      get() {
        return this.$store.getters.getPermeability;
      },
      set(value) {
        this.$store.commit('setPermeability', {
          id: this.selectedRunId,
          permeability: value,
        });
      }
    },
    minimumChannelSize: {
      get() {
        return this.selectedRun.minimumChannelSize;
      },
      set(minimumChannelSize) {
        this.$store.commit('setMinimumChannelSize', {
          minimumChannelSize,
        });
      }
    },
    pipeDiameter: {
      get() {
        return this.selectedRun.pipe_diameter_mm;
      },
      set(value) {
        this.$store.commit('setPipeDiameter', !value ? this.$store.getters.getDefaultRun.pipe_diameter_mm : value);
      }
    },
    pipeLength: {
      get() {
        return this.selectedRun.pipe_length_m;
      },
      set(value) {
        this.$store.commit('setPipeLength', !value ? this.$store.getters.pipe_length_m : value);
      }
    },
    pipeSlope: {
      get() {
        return this.selectedRun.pipe_slope;
      },
      set(value) {
        this.$store.commit('setPipeSlope', !value ? this.$store.getters.getDefaultRun.pipe_slope : value);
      }
    },
    hasExtensionHat: {
      get() {
        return this.$store.getters.hasExtensionHat;
      },
      set(value) {
        this.$store.commit('setExtensionHat', {
          id: this.selectedRunId,
          hasExtension: value,
        });
      }
    },
    waterInflow: {
      get() {
        return this.$store.getters.waterInflow;
      },
      set(value) {
        this.$store.commit('setWaterInflow', {
          id: this.selectedRunId,
          waterInflow: !value ? this.$store.getters.getDefaultRun.water_inflow : value,
        });
      }
    }
  },
}
</script>

<style scoped lang="scss">
h3 {
  margin: 0.25rem 0 0.5rem;
}

label, span, abbr, input, ::v-deep(.p-inputtext), .form-input > button, .p-dropdown.input-dropdown {
  font-size: 0.7rem !important;
}

input, span {
  padding: 2px;
}

input {
  text-align: right;
}

#design-parameters-wrapper {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0.5rem;
  height: fit-content;
  width: 300px;
}

#channel-location-wrapper {
  margin-top: 0.5rem;
}

#channel-run-location {
  width: 198px;
  text-align: left;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;

  abbr {
    width: 1rem;
    padding-left: 1rem;
  }
}

.on-its-own {
  margin-right: 0.5rem;
  font-weight: 500;
}

.form-input {
  width: 50px;
}

.input {
  display: flex;
  justify-content: space-between;
  width: 178px;
  align-items: center;
}

::v-deep(.p-inputtext) {
  padding: 0 2px;
}

::v-deep(.p-inputnumber-input), .p-dropdown.input-dropdown {
  width: 70px;
  text-align: right;
}

.p-dropdown {
  width: 200px;
  padding: 2px;
}

.p-dropdown.input-dropdown ::v-deep(.p-dropdown-trigger) {
  width: auto;
  margin: 0 0.15rem;
}

#raindrop {
  outline: none;
  border-radius: 50%;
  text-decoration: none;
  width: 10px;
  display: flex;
  justify-content: center;
  background-color: #ece9e9;
  border: 1px solid lightgrey;
  padding: 4px 12px;

  &-icon {
    width: 15px;
    height: 15px
  }
}

.radio-label {
  margin-left: 0.5rem;
}

.radio-button {
  display: flex;
  align-items: center;
  margin-right: auto; //Avoid wrapped radios centring awkwardly
}

.radios {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.25rem 0.5rem;
}

.p-inputswitch {
  height: 1rem;

  & :deep(.p-inputswitch-slider)::before {
    width: 0.75rem;
    height: 0.75rem;
    margin-top: -0.375rem;
  }

  &.p-inputswitch-checked :deep(.p-inputswitch-slider)::before {
    transform: translateX(1.75rem);
  }
}

.switch-wrapper {
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.pi-exclamation-triangle {
  color: #f4d436;
  cursor: pointer;
}

.warning > ::v-deep(input) {
  background-color: #fffccc;
  border-color: #f4d436;
}

.pi-exclamation-circle {
  color: red;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 0.7rem;
  margin: 0;
}

.p-invalid > ::v-deep(input) {
  background-color: #fcc;
}

@media (max-width: 1900px) {
  #design-parameters-wrapper {
    width: 280px !important;
  }

  h4 {
    margin: 0 0 0.5rem 0
  }

}

</style>
