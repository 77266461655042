<template>
  <Dialog :header="$t('error')" :style="{width: '600px'}"
          :visible="show" @update:visible="$emit('update:show', $event)">
    <p v-if="isShort">{{ $t('auto-cut.short', {length: -roundedExtraLength}) }}</p>
    <template v-else>
      <p>{{ $t('auto-cut.long', {length: roundedExtraLength}) }}</p>
      <p>{{ $t('auto-cut.corrected') }}</p>
    </template>
    <template #footer>
      <div id="auto-cut-options">
        <Button v-if="isShort" :label="$t('auto-cut.run')" @click="cutRun"/>
        <Button v-else :label="$t('auto-cut.head')" icon="pi pi-step-forward-alt" @click="cutHead"/>
        <Button :label="$t('auto-cut.manual')" @click="$emit('update:show', false)"/>
        <Button v-if="!isShort" :label="$t('auto-cut.discharge')" icon="pi pi-step-backward-alt" icon-pos="right" @click="cutDischarge"/>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    name: 'AutoCutDialog',
    props: ['show'],
    emits: ['update:show'],
    inject: ['doCalculate'],
    computed: {
        ...mapGetters({
            allChannels: 'getChannelChoices',
            rawChannels: 'getRunChannels',
            runTargetLength: 'getChannelLength',
            runLength: 'getChannelsConfirmedLength',
        }),
        ...mapGetters([
            'hasHeadCutting',
            'getHeadCuttingDistance',
            'hasDischargeCutting',
            'getDischargeCuttingDistance',
        ]),
        channelMap() {
            return new Map(this.allChannels.map((channel, i) => [channel, i]));
        },
        runHeadChannels() {
            return this.runChannels((a, b) => this.channelMap.get(a) - this.channelMap.get(b));
        },
        runDischargeChannels() {
            return this.runChannels((a, b) => this.channelMap.get(b) - this.channelMap.get(a));
        },
        extraLength() {
            return this.runLength - this.runTargetLength;
        },
        roundedExtraLength() {
            return +this.extraLength.toFixed(3); //Cut lengths are measured down to 1mm
        },
        isShort() {
            return this.extraLength < 0;
        },
    },
    methods: {
        runChannels(sorter) {
            return this.rawChannels.slice().sort(sorter);
        },
        ...mapMutations([
            'setChannelLength',
            'setHeadCutting',
            'setHeadCuttingDistance',
            'setDischargeCutting',
            'setDischargeCuttingDistance',
            'updateChannelUse',
        ]),
        cutRun() {
            this.setChannelLength({channelLength: this.runLength});
            this.$emit('update:show', false);
            this.doCalculate();
        },
        cutHead() {
            let toRemove_mm = Math.round(this.extraLength * 1000) + (this.hasHeadCutting && this.getHeadCuttingDistance);
            const cutChannels = [];

            on: for (const channel of this.runHeadChannels) {
                let count = channel.number;
                while (channel.channel_length_mm <= toRemove_mm) {
                    toRemove_mm -= channel.channel_length_mm;
                    if (--count <= 0) {//Entire usage of this channel removed
                        cutChannels.push(channel);
                        continue on;
                    }
                }
                this.setHeadCutting(true);
                this.setHeadCuttingDistance(toRemove_mm);
                if (channel.number > count) this.updateChannelUse({channel, value: count});
                for (const cut of cutChannels) this.updateChannelUse({channel: cut, value: 0});
                break;
            }

            this.$emit('update:show', false);
            this.doCalculate();
        },
        cutDischarge() {
            let toRemove_mm = Math.round(this.extraLength * 1000) + (this.hasDischargeCutting && this.getDischargeCuttingDistance);
            const cutChannels = [];

            on: for (const channel of this.runDischargeChannels) {
                let count = channel.number;
                while (channel.channel_length_mm <= toRemove_mm) {
                    toRemove_mm -= channel.channel_length_mm;
                    if (--count <= 0) {//Entire usage of this channel removed
                        cutChannels.push(channel);
                        continue on;
                    }
                }
                this.setDischargeCutting(true);
                this.setDischargeCuttingDistance(toRemove_mm);
                if (channel.number > count) this.updateChannelUse({channel, value: count});
                for (const cut of cutChannels) this.updateChannelUse({channel: cut, value: 0});
                break;
            }

            this.$emit('update:show', false);
            this.doCalculate();
        },
    },
};
</script>

<style lang="scss" scoped>
p {
  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

#auto-cut-options {
  display: flex;
  justify-content: space-between;
}
</style>
